/* frontend/app/src/index.css */

@font-face {
  font-family: 'NeutraText';
  src: url('assets/fonts/Neutra-Text-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NeutraText';
  src: url('assets/fonts/Neutra-Text-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NeutraText';
  src: url('assets/fonts/Neutra-Text-TF.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NeutraText';
  src: url('assets/fonts/NeutraText-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NeutraText';
  src: url('assets/fonts/NeutraText-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'NeutraText';
  src: url('assets/fonts/NeutraText-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NeutraText';
  src: url('assets/fonts/NeutraText-BookItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'NeutraText';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
